import React, { Component } from 'react'
import { Form, Button, Container } from 'react-bootstrap'

export default class Contacts extends Component {
    render() {
		return (
			<Container style={{width: '500px'}}>
				<h1 classname="text-center">Contact us</h1>
				<Form>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" palceholder="Enter email" />
						<Form.Text>
							We`ll never share your email
						</Form.Text>
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Form.Label>Text</Form.Label>
						<Form.Control type="textarea" palceholder="Enter text" rows="3" />
					</Form.Group>
					<Form.Group controlId="formBasicCheckbox">
						<Form.Check type="checkbox" label="Confirmation" />
					</Form.Group>
					<Button variant="primary" type="submit">Send</Button>
				</Form>
			</Container>
		)
    }
}
