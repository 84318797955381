import React, { Component } from 'react'
import { Tab, TabContainer, TabContent, Row, Col, Nav } from 'react-bootstrap';
import developmentImg from '../assets/Development.jpg';
import devopsImg from '../assets/DevOps.jpg';

export default class About extends Component {
    render() {
		return (
			<TabContainer>
				<Tab.Container id="ledt-tabs-example" defaultActiveKey="k1">
					<Row>
						<Col sm={2}>
							<Nav variant="pills" className="flex-column mt-2">
								<Nav.Item>
									<Nav.Link eventKey="k1">Development</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="k2">DevOps</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col sm={9}>
							<TabContent className="mt-3">
								<Tab.Pane eventKey="k1">
									<img src={developmentImg} className="img-fluid rounded mx-auto" alt="home" />
									<p>
										Development
									</p>
								</Tab.Pane>
								<Tab.Pane eventKey="k2">
									<img src={devopsImg} className="img-fluid rounded mx-auto" alt="blog" />
									<p>
										Practices that combines software development (Dev) and IT operations (Ops). It aims to shorten the systems development life cycle and provide continuous delivery with high software quality
									</p>
								</Tab.Pane>
							</TabContent>
						</Col>
					</Row>
				</Tab.Container>
			</TabContainer>
		)
    }
}
