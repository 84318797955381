import React, { Component } from 'react';
import CarouselBox from '../Components/CarouselBox';
import { Container, CardGroup, CardImg, Card } from 'react-bootstrap';
import developmentImg from '../assets/Development.jpg';
import devopsImg from '../assets/DevOps.jpg';
import linuxImg from '../assets/Linux.jpg';

export default class Home extends Component {
    render() {
        return (
			<>
			<CarouselBox />
			<Container>
				<h2 className="text-center m-4">Our</h2>

				<CardGroup className="m-2">
					<Card className="m-4" border="primary">
						<CardImg
						variant="top"
						src={developmentImg}
						/>
						<Card.Body>
							<Card.Text>
								Our developers
							</Card.Text>
						</Card.Body>
					</Card>
					<Card className="m-4" border="primary" text="success">
						<CardImg
						variant="top"
						src={devopsImg}
						/>
						<Card.Body>
							<Card.Text>
								Practices that combines software development (Dev) and IT operations (Ops)
							</Card.Text>
						</Card.Body>
					</Card>
					<Card className="m-4" border="primary">
						<CardImg
						variant="top"
						src={linuxImg}
						/>
						<Card.Body>
							<Card.Text>
								Linux systems
							</Card.Text>
						</Card.Body>
					</Card>
					</CardGroup>
				</Container>
			</>
		)
    }
}
