import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import homeImg from '../assets/Home.jpg';
import blogImg from '../assets/Blog.jpg';
import aboutImg from '../assets/About.jpg';
import contactsImg from '../assets/Contacts.jpg';

export default class CarouselBox extends Component {
    render() {
		return (
			<Carousel>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src= { homeImg }
						alt="Home"
					/>

				<Carousel.Caption>
					<h3>Home</h3>
					<p>Description Home</p>
				</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<a href="/blog">
						<img
						className="d-block w-100"
						src= { blogImg }
						alt="Blog"
						/>
					</a>
				<Carousel.Caption>
					<h3>Blog</h3>
					<p>Description Blog</p>
				</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<a href="/about">
						<img
						className="d-block w-100"
						src= { aboutImg }
						alt="About"
						/>
					</a>
				<Carousel.Caption>
					<h3>About</h3>
					<p>Description About</p>
				</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<a href="/contacts">
						<img
						className="d-block w-100"
						src= { contactsImg }
						alt="Contacts"
						/>
					</a>
				<Carousel.Caption>
					<h3>Contacts</h3>
					<p>Description Contacts</p>
				</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		)
    }
}
