import React, { Component } from 'react'
import { Tab, TabContainer, TabContent, Row, Col, Nav } from 'react-bootstrap';
import windowsImg from '../assets/Windows35.jpg';
import linuxImg from '../assets/Linux35.jpg';
import diffImg from '../assets/Diff35.jpg';

export default class Blog extends Component {
    render() {
		return (
			<TabContainer>
				<Tab.Container id="ledt-tabs-example" defaultActiveKey="k1">
					<Row>
						<Col sm={2}>
							<Nav variant="pills" className="flex-column mt-2">
								<Nav.Item>
									<Nav.Link eventKey="k1">Linux</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="k2">Windows</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="k3">Diff</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col sm={9}>
							<TabContent className="mt-3">
								<Tab.Pane eventKey="k1">
									<img src={linuxImg} className="rounded mx-auto" height="35" alt="Linux" /><h2>Linux</h2>
									<p>
										...
									</p>
								</Tab.Pane>
								<Tab.Pane eventKey="k2">
									<img src={windowsImg} className="rounded mx-auto" height="35" alt="Windows" /><h2>Windows</h2>
									<p>
										...
									</p>
								</Tab.Pane>
								<Tab.Pane eventKey="k3">
									<img src={diffImg} className="rounded mx-auto" height="35" alt="Diff" /><h2>Different</h2>
									<p>
										...
									</p>
								</Tab.Pane>
							</TabContent>
						</Col>
					</Row>
				</Tab.Container>
			</TabContainer>

		)
    }
}
