import React, { Component } from 'react';
import { Navbar, Form, FormControl, Nav, Container } from 'react-bootstrap';
import logo from './img/logo192.png';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import Blog from '../Pages/Blog';
import About from '../Pages/About';
import Contacts from '../Pages/Contacts';

export default class Header extends Component {
    render() {
		return (
			<>
			<Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
				<Container>
					<Navbar.Brand href="/">
						<img
						src={logo}
						height="25"
						width="25"
						className="d-inline-block align-top img-circle"
						alt="logo"
						/>&nbsp;Atreds.pw
					</Navbar.Brand>
					<Navbar.Toggle area-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							<Nav.Link href="/">Home</Nav.Link>
							<Nav.Link href="/blog">Blog</Nav.Link>
							<Nav.Link href="/about">About</Nav.Link>
							<Nav.Link href="/contacts">Contacts</Nav.Link>
						</Nav>
						<Form inline="True">
							<FormControl
							type="text"
							placeholder="Search"
							className="mr-sm-2"
							/>
						</Form>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			<Router>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="blog" element={<Blog />} />
					<Route path="about" element={<About />} />
					<Route path="contacts" element={<Contacts />} />
					<Route path="*" element={<Home />} />
				</Routes>
			</Router>
			</>
		)
    }
}